import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './components/App';
import axios from "axios";

axios.defaults.baseURL = "https://api.microplay.net/";

axios.interceptors.request.use((config) => {
    config.headers['request-startTime'] = new Date().getTime();
    return config
})

axios.interceptors.response.use((response) => {
    const currentTime = new Date().getTime()      
    const startTime = response.config.headers['request-startTime']      
    response.headers['request-duration'] = currentTime - startTime      
    return response
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);