import '../css/App.css';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import { useEffect } from 'react';
import { useState, Suspense } from 'react';
import axios from "axios";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import bg from "../assets/bg.jpg"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Tooltip } from 'react-tooltip'

function Capitalize(t) {
    var words = t.split(' ');

    var capitalized = words.map(function (slowo) {
        return slowo.charAt(0).toUpperCase() + slowo.slice(1);
    });


    return capitalized.join(' ');
}

function StatusHistoryComponent(p) {

    const [date, setDate] = useState("00/00/0000, 00:00");

    function GetReadeableTimeFormat(r) {
        var date = new Date(r * 1000);
        const d = date.toLocaleDateString('en-US', { timeZone: "Etc/UTC" }).trim();
        const t = date.toLocaleTimeString('en-US', { timeZone: "Etc/UTC" }).trim();
        return d + ", " + t
    }

    useEffect(() => {
        axios.get("/status/history").then((res) => {
            const unix_timestamp = p.data.time;
            setDate(GetReadeableTimeFormat(unix_timestamp))
        });
    }, []);

    var serviceA = "";
    var serviceB = "";
    var className = "";

    if (p.data.status === "error") {
        serviceA = "We are investigating the issue";
        serviceB = "Service Disruption";
        className = "red";
    } else if (p.data.status === "malfunction") {
        serviceA = "Some components has degraded performance.";
        serviceB = "Degraded Performance";
        className = "warn";
    }

    if (p.data) {

        return (
            <div className="h">
                <div className={`info margin0 ${className}`}>
                    {p.status === "error" ? <>{serviceA}</> : null}
                    <span>{serviceB}</span>
                </div>
                <div className="group">
                    <span className="t">ID</span>
                    <span>{p.data._id}</span>
                </div>
                <div className="group">
                    <span className='t'>Incident Status</span>
                    <span>{serviceB}</span>
                </div>
                <div className="group">
                    <span className='t'>Components</span>
                    <span>{Capitalize(p.data.components.join(", ").replaceAll("_", " "))}</span>
                </div>
                <div className="group" style={{marginBottom: 0}}>
                    <span className='t'>Time</span>
                    <span>{date}</span>
                </div>
                {p.data.steps.map((step =>
                    <div className="smallGroup" key={step.time}>
                        <span className='t'>
                            {GetReadeableTimeFormat(step.time)}
                        </span>
                        <span dangerouslySetInnerHTML={{ __html: step.msg.replaceAll("[Monitoring]", "<b>[Monitoring]</b>").replaceAll("[Investigating]", "<b>[Investigating]</b>") }}></span>
                    </div>
                ))}

            </div>
        );
    }
}

function Component(p) {

    const [data, setData] = useState(null);

    useEffect(() => {
        axios.get("/status?system=" + p.name.toLowerCase()).then(({ data }) => {
            setData(data);
        });
    }, []);

    if (data) {
        return (
            <div className="component">
                <Tooltip id="t" animation={{open: {effect: "none"}, close: {effect: "none"}}}/>
                <img src={p.icon} alt='GalaxyBlox Icon' width={80} height={80} draggable={false}/>
                <div className="column">
                    <h2>{p.name}</h2>
                    <ul>
                        {Object.keys(data.systems).map(key => (
                            <li key={key}>
                                {data.systems[key] === "active" ? <CheckCircleRoundedIcon fontSize='small' className='green' data-tooltip-id="t" data-tooltip-content="Service Active"/> : null}
                                {data.systems[key] === "error" ? <CancelRoundedIcon fontSize='small' className='red' data-tooltip-id="t" data-tooltip-content="Service Outtage"/> : null}
                                {data.systems[key] === "malfunction" ? <WarningRoundedIcon fontSize='small' className='warning' data-tooltip-id="t" data-tooltip-content="Service Malfunction"/> : null}
                                {Capitalize(key.replaceAll("_", ' '))}
                                {console.log(key.replaceAll("_", " "))}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    } else { return (
        <div className="component">
                <img src={p.icon} alt='GalaxyBlox Icon' width={80} height={80} />
                <div className="column">
                    <svg width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" fill="currentColor">
                        <circle cx="50" cy="50" fill="none" stroke="#232323" stroke-width="6" r="22" stroke-dasharray="103.67255756846316 36.55751918948772">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                        </circle>
                    </svg>
                </div>
            </div>
    ); }
}

function App() {

    const [snake, setSnake] = useState(false);
    const [result, setResult] = useState({
        "message": "Loading...",
        "status": "active"
    })
    const [responseTime, setResponseTime] = useState(0);
    const [StatusHistory, setStatusHistory] = useState(null);

    useEffect(() => {
        axios.get("/").then((res) => {
            setResult(res.data);
            setResponseTime(res.headers['request-duration']);
        });
        axios.get("/status/history").then((res) => {
            setStatusHistory(res.data.reverse());
        });
    }, []);

    function ShowEasterEgg() {
        setSnake(true);
        document.body.style.overflow = "hidden";
    }

    return (
        <>
            <div className="App">
                {snake ? <><div className="modal-container"><CloseRoundedIcon fontSize='large' onClick={() => { setSnake(false); document.body.style.overflow = "auto"; }} /><iframe src='snake.html' className='snake' title='snake' /></div></> : ""}
                <img src="https://cdn.discordapp.com/attachments/1137055496155709480/1154822973174337587/Microplay.png?ex=654e6034&is=653beb34&hm=a02772eb06e2f67eefc4165c3231ff51c58c6ff4ea8e93b2ef00a5bc1ecb2897&" width={80} className='logo' alt="Microplay logo" onClick={() => ShowEasterEgg()} draggable={false}/>
                <h1>Microplay Network Status</h1>
                <div className="center">
                    Official status provided by Microplay Interactive server managers team.
                </div>
                {
                    result && responseTime ? <>
                        <div className={`info ${result["status"] === "We are investigating the issue." ? "red" : ""} ${result["status"] === "Some components has degraded performance." ? "warn" : ""}`}>
                            {result.message}
                            {result.status === "All systems operational." ? <span>Updated a few seconds ago.</span> : null}
                            {result.status === "Some components has degraded performance." ? <span>Degraded performance</span> : null}
                            {result.status === "We are investigating the issue." ? <span>Service Disruption</span> : null}
                        </div>
                        <div className="info white">
                            API response time<span>{responseTime} ms</span>
                        </div></> : <>
                        <Skeleton count={2} height={60}/>
                        </>
                }
                <div className="banner">
                    <h1>Found an outtage?</h1>
                    Report it on our Discord server.<br/>
                    <button style={{marginTop: 50}} onClick={() => window.open("https://u.microplay.net/dc?rel=status")}>Join</button>
                </div>
                <h2>Component Status</h2>
                <Component name="GalaxyBlox" icon="https://cdn.discordapp.com/attachments/1137055496155709480/1140657843314380850/image_3.png?ex=6548fc66&is=65368766&hm=0682c526a1121d4dc7b2ffdabcc30d8d3421529fe3e20c98a4d21370a829d641&" />
                <Component name="Network" icon="https://cdn.discordapp.com/attachments/1137055496155709480/1154822973174337587/Microplay.png?ex=654e6034&is=653beb34&hm=a02772eb06e2f67eefc4165c3231ff51c58c6ff4ea8e93b2ef00a5bc1ecb2897&" />
                <h2>Status History</h2>
                {StatusHistory ? StatusHistory.map((s, idx) => <StatusHistoryComponent status="error" data={s} idx={idx} key={idx} />) : <>
                <Skeleton height={60} count={5}/>
                </>}
                <div className="footer">
                    <img src="https://media.discordapp.net/attachments/1137055496155709480/1154822973174337587/Microplay.png?ex=654e6034&is=653beb34&hm=a02772eb06e2f67eefc4165c3231ff51c58c6ff4ea8e93b2ef00a5bc1ecb2897&=&width=395&height=395" width={50} alt='Microplay Interactive' draggable={false}/>
                    <h3>Data provided by:</h3>
                    <div style={{width: 320, alignItems: "center", display: "flex"}}>

                    <VerifiedRoundedIcon/> Microplay Interactive Studio
                    </div>
                    <h3>Join for support</h3>
                    <a href="https://u.microplay.net/dc">u.microplay.net/dc</a>
                </div>
            </div>
        </>
    );
}

export default App;